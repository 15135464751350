import { CATEGORIES } from '@kijiji/category'
import {
  type FilterPriceType,
  AdditionalFlags,
  useGetListingsMostRecentQuery,
} from '@kijiji/generated/graphql-types'
import { colors, spacing } from '@kijiji/theme'
import { useTranslation } from 'next-i18next'

import { HomepageCarouselContainer } from '@/components/homepage/homepage-gallery/styled'
import { CoreListingCard } from '@/components/shared/core-listing-card/CoreListingCard'
import { GalleryHeaderWrapper } from '@/components/shared/gallery/GalleryHeaderWrapper'
import { GalleryWrapper } from '@/components/shared/gallery/GalleryWrapper'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { ListingsCarousel } from '@/components/shared/listings-carousel/ListingsCarousel'
import { MOST_RECENT_LISTINGS_TO_FETCH } from '@/features/listing/components/galleries/most-recent-listings/constants/constants'
import { getSeeAllLink } from '@/features/listing/components/galleries/most-recent-listings/utils/getSeeAllLink'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

export type ListingsMostRecentProps = {
  locationId: number
  locationName?: string | null
  priceType?: FilterPriceType
}

export const ListingsMostRecentGallery = ({
  locationId,
  locationName,
  priceType,
}: ListingsMostRecentProps) => {
  const { t } = useTranslation('home')

  const { data } = useGetListingsMostRecentQuery({
    fetchPolicy: 'cache-first',
    variables: {
      by: {
        query: {
          categoryId: CATEGORIES.ROOT_CATEGORY_ID,
          location: {
            id: locationId,
          },
          additionalFlagFilters: [AdditionalFlags.Images],
          ...(priceType && {
            attributeFilters: [
              {
                filterName: 'priceType',
                values: [priceType],
              },
            ],
          }),
        },
      },
      pagination: {
        limit: MOST_RECENT_LISTINGS_TO_FETCH,
      },
    },
  })

  const handleListingClick = () => {
    trackEvent({ action: GA_EVENT.SelectPromotion, label: 'listings_most_recent' })
  }

  const slides =
    data?.searchResultsPage?.results?.mainListings.map((listing, index) => {
      return (
        <CoreListingCard
          index={index}
          key={`listing-${listing.id}`}
          listing={listing}
          onClick={handleListingClick}
          linkTarget="_self"
        />
      )
    }) ?? []

  const seeAllLink = getSeeAllLink(
    data?.searchResultsPage.searchQuery.seoUrl,
    locationId,
    locationName,
    t
  )

  return (
    <GalleryWrapper data-testid="most-recent-listings-section">
      <Spacing mBottom={spacing.default} data-testid="most-recent-gallery-header">
        <GalleryHeaderWrapper justifyContent="space-between" alignItems="center">
          <HeadlineText as="h2" color={colors.grey.primary} size="medium" weight="regular">
            {t('listings_most_recent.title')}
          </HeadlineText>
          <LinkCustom href={seeAllLink} weight="regular" size="small" variant="secondary">
            {t('gallery.see_all.label')}
          </LinkCustom>
        </GalleryHeaderWrapper>
      </Spacing>

      <HomepageCarouselContainer>
        <ListingsCarousel
          name="most-recent-listings"
          shouldShowArrows={{ small: false, medium: false, large: true }}
          slides={slides}
          customShowcaseOptions={{ slidesToScroll: 'auto' }}
          slidesToShow={{ small: 2, medium: 5.5, large: 5 }}
        />
      </HomepageCarouselContainer>
    </GalleryWrapper>
  )
}
