import { CATEGORIES } from '@kijiji/category'
import {
  type CoreListing,
  useGetListingHomepageGalleryQuery,
} from '@kijiji/generated/graphql-types'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { type FC, type ReactNode, useMemo, useState } from 'react'

import { getListingHomepageGalleryVariables } from '@/components/homepage/homepage-gallery/getHomepageGallery'
import { HomepageGalleryHeader } from '@/components/homepage/homepage-gallery/HomepageGalleryHeader'
import { HomepageCarouselContainer } from '@/components/homepage/homepage-gallery/styled'
import {
  HP_GALLERY_REQUEST_CHUNKS,
  SLIDES_PER_DEVICE,
} from '@/components/homepage/shared/homepageConstants'
import {
  type CoreListingCardProps,
  CoreListingCard,
} from '@/components/shared/core-listing-card/CoreListingCard'
import { GalleryWrapper } from '@/components/shared/gallery/GalleryWrapper'
import { ListingsCarousel } from '@/components/shared/listings-carousel/ListingsCarousel'
import { LoadingGallery } from '@/components/shared/loading-gallery/LoadingGallery'
import { isValidLocation } from '@/domain/location/isValidLocation'
import { isUserAuthenticated } from '@/features/auth/constants/user'
import { mergeFetchMore } from '@/lib/apollo/mergeFetchMore'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { Flex } from '@/ui/atoms/flex'
import { isMobileDevice } from '@/utils/userAgent'

export type HomepageGalleryProps = {
  userAgent: string
  locationId: number
  locationName?: string | null
}

export const HomepageGallery: FC<HomepageGalleryProps> = ({
  userAgent = '',
  locationId,
  locationName,
}) => {
  const [onInitialLoad, setOnInitialLoad] = useState<boolean>(true)

  const { t } = useTranslation('home')

  const [hasReachedLastPage, setHasReachedLastPage] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)

  const { status } = useSession()
  /**
   * When logged out, we fetch the data server-side for SEO purposes & skip the loading skeleton so that the links are crawlable
   * When logged in, we fetch the data client-side so need the loading skeleton
   */
  const skipLoadingSkeletonForSSR = isUserAuthenticated(status)

  /**
   * Need to hardcode the page to 0 for the initial fetch
   * we pass the dynamic page state to the fetchMore function
   */
  const initialFetchVariables = useMemo(
    () => getListingHomepageGalleryVariables(userAgent, locationId, 0),
    [userAgent, locationId]
  )

  const { loading, data, fetchMore } = useGetListingHomepageGalleryQuery({
    fetchPolicy: 'cache-first',
    skip: !isValidLocation(locationId),
    variables: initialFetchVariables,
    onCompleted: ({ listingHomepageGallery }) => {
      const dataCount = listingHomepageGallery?.items?.length
      const isLastPage = !dataCount || dataCount < HP_GALLERY_REQUEST_CHUNKS

      setHasReachedLastPage(isLastPage)

      /**
       * Initial load has been completed when the HP Gallery first loads.
       * After that it shouldn't be set to true again and the loading state won't be re-triggered
       */
      if (onInitialLoad) {
        setOnInitialLoad(false)
      }
    },
  })

  const handleYourAdHereTracking = () => {
    trackEvent({ action: GA_EVENT.PostAdBegin, label: 'btn=hp-glry-ad;' })
  }

  const handleImpressionsTracking = (listingId: string, position: number) => {
    trackEvent({
      action: GA_EVENT.SelectPromotion,
      label: `partnerid=${listingId};position=${position}`,
    })
  }

  const handleLoadMore = async () => {
    if (hasReachedLastPage || loading) return

    await fetchMore({
      variables: getListingHomepageGalleryVariables(userAgent, locationId, page + 1),
      /** Merge items on each fetch */
      updateQuery(prevQueryResult, { fetchMoreResult }) {
        const prevItems = prevQueryResult.listingHomepageGallery?.items
        const newItems = fetchMoreResult.listingHomepageGallery?.items

        const mergedItems = mergeFetchMore<CoreListing>({
          prevItems: prevItems,
          fetchMoreItems: newItems,
        })

        /**
         * Reached the end of the page once there are no more items,
         * or the query returned less items than requested */
        const dataCount = newItems?.length
        const isLastPage = !dataCount || dataCount < HP_GALLERY_REQUEST_CHUNKS

        setHasReachedLastPage(isLastPage)
        !isLastPage && setPage((prev) => prev + 1)

        return {
          __typename: 'Query',
          listingHomepageGallery: {
            __typename: 'ListingHomepageGallery',
            items: mergedItems,
            seeAllLink: fetchMoreResult.listingHomepageGallery?.seeAllLink ?? '',
          },
        }
      },
    })
  }

  const getPlaceholderSlides = () => {
    const itemsCount = data?.listingHomepageGallery?.items?.length ?? 0

    const remainingSpaces = !isMobileDevice(userAgent)
      ? itemsCount < SLIDES_PER_DEVICE.DESKTOP
        ? Math.ceil(SLIDES_PER_DEVICE.DESKTOP - itemsCount)
        : 0
      : itemsCount < SLIDES_PER_DEVICE.TABLET
        ? Math.ceil(SLIDES_PER_DEVICE.TABLET - itemsCount)
        : 0

    const props: Omit<CoreListingCardProps, 'index'> = {
      onClick: handleYourAdHereTracking,
      listing: {
        id: 'your_ad_here',
        categoryId: CATEGORIES.ROOT_CATEGORY_ID,
        imageUrls: ['/next-assets/images/not-found.jpg'],
        title: t('gallery.your_ad_here.label'),
        url: t('gallery.your_ad_here.link'),
        location: { id: locationId, name: locationName ?? '' },
        isPlaceholder: true,
      },
    }

    if (remainingSpaces) {
      return Array.from({ length: remainingSpaces }, (_, index) => (
        <CoreListingCard
          key={`hp-gallery-placeholder-${index}`}
          index={index + itemsCount}
          linkTarget="_self"
          {...props}
        />
      ))
    }

    return [<CoreListingCard key={`hp-gallery-placeholder`} index={itemsCount} {...props} />]
  }

  let slides: ReactNode[] =
    data?.listingHomepageGallery?.items?.map((item, index) => {
      if (!item) return null

      const position = index + 1
      return (
        <CoreListingCard
          key={`hp-gallery-${item.id}`}
          index={index}
          listing={item}
          linkTarget="_self"
          onClick={() => handleImpressionsTracking(item.id, position)}
        />
      )
    }) || []

  /**
   * Add placeholder listing at the end of the carousel once the data has reached the last page
   * */
  if (hasReachedLastPage) {
    slides = [...slides, ...getPlaceholderSlides()]
  }

  /** Should not show section if data has loaded, but there is no data to show */
  if (onInitialLoad === false && !data?.listingHomepageGallery?.items?.length) return null

  return (
    <GalleryWrapper data-testid="homepage-gallery-section">
      {onInitialLoad && skipLoadingSkeletonForSSR ? (
        <Flex flexDirection="column" data-testid="hp-gallery-loading">
          <LoadingGallery hasLoadingTitle itemsCount={6} useLegacyLgDesktopBreakpoint />
        </Flex>
      ) : (
        <>
          <HomepageGalleryHeader
            locationId={locationId}
            locationName={locationName}
            seeAllLink={data?.listingHomepageGallery?.seeAllLink}
          />

          <HomepageCarouselContainer>
            <ListingsCarousel
              callbackOnScroll={{ callbackFn: handleLoadMore, percentage: 50 }}
              slides={slides}
              name="hp-gallery-carousel"
              shouldShowArrows={{ small: false, medium: false, large: true }}
              customShowcaseOptions={{ slidesToScroll: 'auto' }}
              slidesToShow={{
                small: SLIDES_PER_DEVICE.MOBILE,
                medium: SLIDES_PER_DEVICE.TABLET,
                large: SLIDES_PER_DEVICE.DESKTOP,
              }}
            />
          </HomepageCarouselContainer>
        </>
      )}
    </GalleryWrapper>
  )
}
