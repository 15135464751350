import { ListingPriceType } from '@kijiji/generated/graphql-types'
import { formatPrice, priceFromCents } from '@kijiji/money'
import { type TFunction } from 'next-i18next'

import { isAmountPrice } from '@/domain/listings/isAmountPrice'
import { type RouteLocale, ROUTE_LOCALE } from '@/domain/locale'
import { type GenericListing } from '@/features/listing/types/listings'

type GetListingPriceProps = {
  /**
   * Listing price
   * It should allow passing a number when trying to get display label for the original price field
   */
  price: GenericListing['price'] | number
  /** User's current locale */
  routeLocale: RouteLocale
  /** Listing translation */
  t: TFunction
}

/**
 * Returns formatted listing price to be displayed
 */
export const getListingPrice = ({
  price,
  t,
  routeLocale,
}: GetListingPriceProps): string | undefined => {
  if (typeof price !== 'number') {
    /** If price is not fixed, it should show type label */
    if (price?.type !== ListingPriceType.Fixed) {
      switch (price?.type) {
        case ListingPriceType.Free:
        case ListingPriceType.GiveAway:
          return t(`listing:price.types.give_away`)
        case ListingPriceType.Contact:
        case ListingPriceType.SwapTrade:
          return t(`listing:price.types.${price.type.toLowerCase()}`)
        default:
          return t(`listing:price.types.contact`)
      }
    }

    /** If type if fixed and amount is NULL - return "Contact" label */
    if (!isAmountPrice(price) || price.amount === null) {
      return t(`listing:price.types.${ListingPriceType.Contact.toLowerCase()}`)
    }
  }

  const priceAmount = typeof price === 'number' ? price : price.amount

  /**
   * It should not return ant value if the amount is 0
   * This logic catches categories like "services" and "jobs" that should not display a price amount
   * */
  if (priceAmount === 0) {
    return
  }

  /**
   * Return a formatted price:
   * - Locale dependent
   * - Suppress cents if it is "00"
   */
  const suppressCents = priceFromCents(parseInt(`${priceAmount}`)).cents === '00'
  return (
    formatPrice(priceAmount, { isFrench: routeLocale === ROUTE_LOCALE.fr, suppressCents }) ??
    undefined
  )
}
