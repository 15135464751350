import { type UserLocation } from '@kijiji/generated/graphql-types'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { HomepageGallery } from '@/components/homepage/homepage-gallery/HomepageGallery'
import { KijijiCentralGallery } from '@/components/homepage/kijiji-central-gallery/KijijiCentralGallery'
import { PopularOnAutos } from '@/components/homepage/popular-categories/PopularOnAutos'
import { PopularOnBuyAndSell } from '@/components/homepage/popular-categories/PopularOnBuyAndSell'
import { PopularOnRealEstate } from '@/components/homepage/popular-categories/PopularOnRealEstate'
import { RecentlyViewedAds } from '@/components/homepage/recently-viewed-ads/RecentlyViewedAds'
import { RecommendedForYou } from '@/components/homepage/recommended-for-you/RecommendedForYou'
import { HomepageSectionWrapper } from '@/components/homepage/styled'
import { GptLeaderBase } from '@/components-page/homepage/advertisement/GptLeaderBase'
import { GptLeaderBottom } from '@/components-page/homepage/advertisement/GptLeaderBottom'
import { SignInBanner } from '@/features/auth/components/sign-in-banner/SignInBanner'
import { ListingsMostRecentGallery } from '@/features/listing/components/galleries/most-recent-listings/ListingsMostRecentGallery'
import { useBrandedTileCampaign } from '@/hooks/campaigns/useBrandedTileCampaign'
import { useLocale } from '@/hooks/useLocale'
import { Spacing } from '@/ui/atoms/spacing'

type FeedFallbackComponentsProps = {
  getSeoUrl: (categoryId: number, index?: number | undefined) => string
  location: UserLocation
  userAgent: string
  isUserLoggedIn: boolean
}
export const FeedFallbackComponents: FC<FeedFallbackComponentsProps> = ({
  getSeoUrl,
  location,
  userAgent,
  isUserLoggedIn,
}) => {
  const { apiLocale } = useLocale()
  const { spacing } = useTheme()
  /**
   * The branded tile campaign is used on the popular categories section
   * it will be added to the first tile in the assigned category section
   * And will remove its last static item.
   *  */
  const { campaign } = useBrandedTileCampaign(location.id)

  return (
    <>
      <Spacing mTop={spacing.husky}>
        <RecommendedForYou />
      </Spacing>

      <HomepageGallery
        userAgent={userAgent}
        locationName={location.name[apiLocale]}
        locationId={location.id}
      />

      {!isUserLoggedIn && ( // eventually, we'll show this to all users
        <Spacing mTop={spacing.husky} mBottom={spacing.husky}>
          <ListingsMostRecentGallery
            locationId={location.id}
            locationName={location.name[apiLocale]}
          />
        </Spacing>
      )}

      <HomepageSectionWrapper mTop={spacing.husky} mBottom={spacing.husky}>
        <PopularOnAutos
          campaign={campaign}
          getSeoUrl={getSeoUrl}
          isRegionLocation={location.isRegion}
          locationName={location.name[apiLocale]}
        />

        <GptLeaderBottom />
      </HomepageSectionWrapper>

      <RecentlyViewedAds />

      <PopularOnBuyAndSell
        campaign={campaign}
        getSeoUrl={getSeoUrl}
        isRegionLocation={location.isRegion}
        locationName={location.name[apiLocale]}
      />

      <HomepageSectionWrapper>
        <Spacing mTop={spacing.husky}>
          <SignInBanner />
        </Spacing>

        <GptLeaderBase />
      </HomepageSectionWrapper>

      <Spacing mTop={spacing.husky} mBottom={spacing.husky}>
        <PopularOnRealEstate
          campaign={campaign}
          getSeoUrl={getSeoUrl}
          isRegionLocation={location.isRegion}
          locationName={location.name[apiLocale]}
        />
      </Spacing>

      <KijijiCentralGallery />
    </>
  )
}
